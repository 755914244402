import { PlusOutlined, MinusOutlined, CheckOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table, Tooltip, InputRef, message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import styled from "styled-components";
import { ReduxActionTypes } from "../../../../ce/constants/ReduxActionConstants";
import { get_dml_sql_build } from './AddTable/utils_dml_sql_command_builder'


const IconDiv = styled.div`
    margin-left: 20px;
    cursor: pointer;
    transition: all .5s;
    padding: 5px;
    &:hover {
        border-radius: 5px;
        background: #e0e0e0;
        transition: all .5s;
    }
`

const IconBox = styled.div`
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: flex-start;
    background-color: #fafafa;
`


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item, old: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {

        try {
            const values = await form.validateFields();
            console.log(values);

            toggleEdit();
            handleSave({ ...record, ...values }, record);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    const inputChange = (e: any) => {
        const word = e.target.value // input的新值
    }

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            >
                <Input ref={inputRef} onPressEnter={save} onChange={inputChange} onBlur={save} placeholder='请输入' />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '20px' }} onClick={toggleEdit}>
                <div style={{ width: '200px', height: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={children} color='#2db7f5'>
                        {children}
                    </Tooltip>

                </div>
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    id: string; // 字段
    type: string;  // 字段类型
    noNull: string; // 非空
    auto: string; // 自增
    remark: string; // 备注
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const TableData: React.FC = (props: any) => {
    const dispatch = useDispatch()
    const [count, setCount] = useState(999999);
    const counter = useSelector(state => state.ui.sql.returnSqlList.body)


    const [dataSource, setDataSource] = useState([]);
    const [source, setSource] = useState([])

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [newList, setNewList] = useState([])
    const [oldList, setOldList] = useState([])

    const handleSave = (row: DataType, old: DataType) => {
        newList.push(row)
        setOldList([old])

        // newList.push(row)
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
        setSource([...source, row])
    };

    // 获取redux的全部树状数据
    const getTreeList = useSelector(state => state.ui.leftTree)
    const [treelist, setTreeList] = useState({})
    useEffect(() => {
        setTreeList(getTreeList)
    }, [getTreeList])


    const columnsList = []
    // 构造table头部的方法
    if (JSON.stringify(props.tableDataItem) != "{}") {
        const { tableDataItem: { id: { tableList } } } = props

        const { tableColumns } = props
        // 拿到最新的数据
        let newItem = tableList
        if (Object.keys(treelist).length !== 0) {
            if (treelist.getTreeList !== undefined && tableList !== undefined) {
                treelist.getTreeList.map(item => {
                    if (item.name == tableList.name) {
                        newItem = item
                    }
                })
            }
        }

        if (tableList) {
            newItem.columns.map(el => {
                let obj = {}
                obj["title"] = el.name
                obj["dataIndex"] = el.name
                obj["editable"] = true;
                obj["width"] = "180px"
                obj["ellipsis"] = true
                columnsList.push(obj)
            })
        } else if (Object.keys(tableColumns).length !== 0) {
            const tableNameMatch = tableColumns.sql.match(/FROM\s+(\w+)/i); // 拿到查询的表名
            // 拿到所有的头部进行处理
            // 找到当前查询的表获取到数据进行遍历动态构建table表头
            let list = {}
            tableColumns.tableData.forEach(el => {
                if (el.name === tableNameMatch[1]) {
                    list = el
                }
            })

            list.columns.map(el => {
                let obj = {}
                obj["title"] = el.name
                obj["dataIndex"] = el.name
                obj["editable"] = true;
                obj["width"] = "180px"
                obj["ellipsis"] = true
                columnsList.push(obj)
            })
        }

    }

    const columns = columnsList.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave
            }),
        };
    });


    useEffect(() => {
        let dataSourceList = []
        if (Object.keys(props.tableDataItem).length == 0) return
        let { tableDataItem: { id: { tableList } } } = props
        const { tableColumns } = props
        // 在这里请求获取字段的接口
        if (tableList) {
            if (tableList.keys.length === 0) return message.warning('该表没有主键，请自行前往数据库添加')
            let keys = tableList.keys[0].columnNames[0] // 获取表主键

            // 判断字段类型是不是datatime，是的话就转换时间格式
            tableList.columns.forEach((el, i) => {
                if (el.type === 'datetime') {
                    let name = tableList.columns[i].name
                    counter.forEach(el => {
                        if (name in el) {
                            const time = timer(el[name])
                            // 如果匹配，可以进行相应的操作
                            el[name] = time; // 修改属性值
                        }
                    });

                }
            })

            counter && counter.map(el => {
                let obj = {}
                let counterKeys = Object.keys(el)

                counterKeys.forEach((item, i) => {
                    // 判断主键和key有没有相同的
                    if (item === keys) {
                        obj['key'] = el[item]
                    }
                    obj[item] = el[item]
                    return obj
                })
                dataSourceList.push(obj)
            })
            setDataSource(dataSourceList)
        } else if (Object.keys(tableColumns).length !== 0) {
            const tableNameMatch = tableColumns.sql.match(/FROM\s+(\w+)/i); // 拿到查询的表名
            let list = {}
            tableColumns.tableData.forEach(el => {
                if (el.name === tableNameMatch[1]) {
                    list = el
                }
            })
            if (list.keys === 0) return message.warning('该表没有主键，请自行前往数据库添加')
            let keys = list.keys[0].columnNames[0] // 获取表主键
            counter && counter.map(el => {
                let obj = {}
                let counterKeys = Object.keys(el)
                counterKeys.forEach(item => {
                    // 判断主键和key有没有相同的
                    if (item === keys) {
                        obj['key'] = el[item]
                    }
                    obj[item] = el[item]
                    return obj
                })
                dataSourceList.push(obj)
            })
            setDataSource(dataSourceList)
        }
    }, [counter])

    // 封装执行sql的接口
    const getSql = (sql: string, id: string, queryList?: Object) => {
        dispatch({
            type: ReduxActionTypes.SUBMIT_SQL,
            payload: {
                datasourceId: id,
                executeBody: sql,
                queryList
            }
        })
    }

    // 时间转换方法
    const timer = (time) => {
        const originalDate = new Date(time);
        const year = originalDate.getUTCFullYear();
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getUTCDate()).padStart(2, '0');
        const hours = String(originalDate.getUTCHours()).padStart(2, '0');
        const minutes = String(originalDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(originalDate.getUTCSeconds()).padStart(2, '0');
        const newFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return newFormattedDate
    }

    //加入多选框
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [updataSql, setUpdataSql] = useState<any>({})
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // 获取主键
        const { tableDataItem: { id: { tableList } } } = props
        if (tableList.keys.length === 0) return message.warning("该表没有主键，请自行前往数据库添加")
        const keys = tableList.keys[0].columnNames[0]
        // 获取表名字
        const { tableDataItem: { id } } = props
        const listName = id.name
        const SqlObj = { id: id.id, name: id.name }

        // 判断如果选中为一条，直接拼sql。如果为多条则遍历选中的id
        if (newSelectedRowKeys.length === 1) {
            const SQL = `DELETE FROM ${listName} where ${keys} = ${newSelectedRowKeys[0]}`
            SqlObj["Sql"] = SQL
        } else {
            const dataArr = []
            newSelectedRowKeys.forEach(el => {
                dataArr.push(el)
            })
            const str = dataArr.join(',')
            const SQL = `DELETE FROM ${listName} where ${keys} in(${str})`
            SqlObj["Sql"] = SQL
        }
        setSelectedRowKeys(newSelectedRowKeys);
        setUpdataSql(SqlObj)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // 删除方法
    const deleteCheck = async () => {
        const { tableDataItem: { id } } = props
        if (JSON.stringify(updataSql) == '{}') {
            return message.warning('请选择要删除的行')
        }
        let queryList = {
            type: 'delete',
            listName: updataSql.name,
            listId: updataSql.id
        }
        getSql(updataSql.Sql, updataSql.id, queryList)
    }

    // 新增数据行
    const addList = () => {
        const newData = {}
        counter && counter.map(el => {

            let counterKeys = Object.keys(el)
            counterKeys.forEach(item => {
                newData[item] = '';
                newData["key"] = count;
            })
        })
        setDataSource([...dataSource, newData]);
        setCount(count + 1);

    }

    // 更新数据库
    const oldValues = useSelector(state => state.ui.sql)
    const updataList = () => {
        const { tableDataItem: { id: { tableList } } } = props
        const { tableDataItem: { id } } = props

        let old_rows = oldValues.returnSqlList.body // 旧的数据
        let new_rows = dataSource // 新的数据
        let title = tableList.name // 表名
        let table_fields = tableList.columns // 所有字段

        const resultArray = old_rows.map((obj1, index) => {
            const obj2 = new_rows[index];
            let isEqual = true;
            for (const key of Object.keys(obj1)) {
                if (obj1[key] !== obj2[key]) {
                    isEqual = false;
                    break;
                }
            }
            if (!isEqual) {
                return { ...obj2, options_type: { value: 1 } };
            }
            return obj2;
        });

        /**
        * this.temp_rows // 旧的值
        * this.rows // 新的值
        * this.table_fields // 所有字段
        * this.get_table_name_from_sql() // 表名
        */
        let sql = get_dml_sql_build(old_rows, resultArray, table_fields, title)
        console.log(sql);
        // 传type 表名 表id
        let queryList = {
            type: 'updata',
            listName: id.name,
            listId: id.id,
        }
        getSql(sql, id.id, queryList)
    }

    // 确认添加行
    const addLine = () => {
        const { tableDataItem: { id } } = props
        const sourceLine = dataSource
        let SQL = ``
        if (sourceLine.length === counter.length) {
            return message.warning('请新增行，再执行本次操作')
        }
        if (sourceLine.length === counter.length + 1) {
            // 将对象中的key字段去掉
            let scouce = sourceLine[counter.length]
            const res = Object.entries(scouce).filter(([key, val]) => key != "key")
            let newKeys = Object.keys(Object.fromEntries(res))
            let NewValues = Object.values(Object.fromEntries(res))

            let str = "`" + newKeys.join("`,`") + "`"
            let values = Object.values(NewValues)
            let strVal = "'" + values.join("','") + "'"
            SQL = `INSERT INTO ${id.name} (${str}) VALUES (${strVal});`

        } else {
            let newArray = extractEqualObjects(dataSource, source)
            let sourceList = []
            let strVal = ''
            let str = ''
            // 删除key
            let newArrayList = newArray.map(el => {
                let newObj = { ...el }
                delete newObj.key
                return newObj
            })

            newArrayList.forEach(item => {
                let keys = Object.keys(item)
                str = "`" + keys.join("`,`") + "`"
                let values = Object.values(item)
                strVal = "'" + values.join("','") + "'"
                SQL = `INSERT INTO ${id.name} (${str}) VALUES (${strVal});` + SQL
            })
        }
        let queryList = {
            type: 'addList',
            listName: id.name,
            listId: id.id,
        }
        getSql(SQL, id.id, queryList)
    }

    // 判断数组里面的对象是不是一样，一样就将其返回出去
    const extractEqualObjects = (arr1, arr2) => {
        var resultArray = [];

        for (var i = 0; i < arr1.length; i++) {
            for (var j = 0; j < arr2.length; j++) {
                if (objectsAreEqual(arr1[i], arr2[j])) {
                    resultArray.push(arr1[i]);
                    break; // 跳出内层循环，避免重复添加
                }
            }
        }
        return resultArray;
    }

    const objectsAreEqual = (obj1, obj2) => {
        for (var key in obj1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }


    return (
        <div>
            <IconBox>
                <IconDiv>
                    <Tooltip placement="top" title={"新增数据行"}>
                        <PlusOutlined onClick={addList} style={{ fontSize: 24, color: '#1677FF' }} />
                    </Tooltip>
                </IconDiv>
                <IconDiv>
                    <Tooltip placement="top" title={"删除选中行"}>
                        <MinusOutlined onClick={deleteCheck} style={{ fontSize: 24, color: '#F56C6C' }} />
                    </Tooltip>
                </IconDiv>
                <IconDiv>
                    <Tooltip placement="top" title={"确认"}>
                        <CheckOutlined onClick={updataList} style={{ fontSize: 24, color: '#1677FF' }} />
                    </Tooltip>
                </IconDiv>
                <IconDiv>
                    <Tooltip placement="top" title={"确认增加行"}>
                        <PlusSquareOutlined onClick={addLine} style={{ fontSize: 24, color: '#f6db27' }} />
                    </Tooltip>
                </IconDiv>
            </IconBox>
            <Table
                components={components}
                bordered
                scroll={{ y: 360, x: 'auto' }}
                dataSource={dataSource}
                columns={columns as ColumnTypes}
                rowSelection={rowSelection}
            />
        </div>
    );
};

export default TableData;