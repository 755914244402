import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import MoadlApprow from "../ModalApprow/ModalApprow";
import createContext from "../../createContext";
const ModalApp = (props) => {
  const { setModal } = props;
  const [setModel, setSetModalOpen] = useState(setModal);
  useEffect(() => {
    setSetModalOpen(props.setModal);
  }, [props]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // 调接口 200 false
    setSetModalOpen(false);
  };
  const handleCancel = () => {
    setSetModalOpen(false);
  };
  return (
    <div>
      <Modal
        title="流程图"
        open={setModel}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1800}
        footer={false}
        destroyOnClose={true}
        // afterClose={() => {}}
      >
        <div>
          <MoadlApprow></MoadlApprow>
        </div>
      </Modal>
    </div>
  );
};
export default ModalApp;
