import deleteXmlApi, { SaveDeleteXml } from "api/deleteXml";
import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";
import { message } from "antd";
type deleteXmlPayload = {
  total: number;
  data: [];
};

function* saveDelete(action: ReduxAction<deleteXmlPayload>) {
  const { payload } = action;
  console.log(111);

  const response: SaveDeleteXml = yield call(deleteXmlApi.saveDelete, payload);
  const isValidResponse: boolean = yield validateResponse(response);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.GET_FLOW_DATALIST,
      payload: {
        pageSize: 10,
        pageNum: 1,
      },
    });
    message.info("删除成功");
  }
}

export default function* deleteXmlSagas() {
  yield all([takeEvery(ReduxActionTypes.DELETE_FLOW_XML, saveDelete)]);
}
