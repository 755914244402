import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  selectFeatureFlags,
  selectUserMenuLit,
} from "selectors/usersSelectors";
import styled from "styled-components";
import StyledHeader from "components/designSystems/appsmith/StyledHeader";
import { AppState } from "@appsmith/reducers";
import { User, ANONYMOUS_USERNAME } from "constants/userConstants";
import {
  AUTH_LOGIN_URL,
  APPLICATIONS_URL,
  matchApplicationPath,
  matchTemplatesPath,
  TEMPLATES_PATH,
  matchTemplatesIdPath,
  TEMPLATES_PATH_DATA,
  matchDataOrigin,
  FLOW_MANAGE_PATH,
  matchFlowPath,
  TEMPLATES_PATH_DATAModal,
  dataModalOrigin,
} from "constants/routes";
import history from "utils/history";
import Button from "components/editorComponents/Button";
import ProfileDropdown from "./ProfileDropdown";
import { Colors } from "constants/Colors";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { ReactComponent as TwoLineHamburger } from "assets/icons/ads/two-line-hamburger.svg";
import MobileSideBar from "./MobileSidebar";
import { Indices } from "constants/Layers";
import { Icon, IconSize } from "design-system";
import { getTemplateNotificationSeenAction } from "actions/templateActions";
import { getTenantConfig } from "@appsmith/selectors/tenantSelectors";

const StyledPageHeader = styled(StyledHeader)<{
  hideShadow?: boolean;
  isMobile?: boolean;
  showSeparator?: boolean;
  showingTabs: boolean;
}>`
  box-shadow: none;
  justify-content: normal;
  background: white;
  height: 48px;
  color: white;
  position: fixed;
  top: 0;
  z-index: ${Indices.Layer9};
  box-shadow: 0px 1px 0px ${Colors.GALLERY_2};
  ${({ isMobile }) =>
    isMobile &&
    `
    padding: 0 12px;
    padding-left: 10px;
  `};
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;

  .t--appsmith-logo {
    svg {
      max-width: 110px;
      width: 110px;
    }
  }
`;

const StyledDropDownContainer = styled.div``;

const PagePlugLogoImg = styled.img`
  height: 32px;
`;

const StyledTwoLineHamburger = styled(TwoLineHamburger)`
  fill: ${Colors.BLACK};
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const Tabs = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  margin-left: ${(props) => props.theme.spaces[16]}px;
  height: 100%;
  gap: ${(props) => `${props.theme.spaces[0]}px ${props.theme.spaces[12]}px`};
  flex: 1;
  padding-top: ${(props) => props.theme.spaces[1]}px;
`;
const TabName = styled.div<{ isSelected: boolean }>`
  color: ${Colors.GRAY};
  border-bottom: 2px solid transparent;
  text-align: center;
  display: flex;
  align-items: center;
  ${(props) =>
    props.isSelected &&
    `border-bottom: 2px solid var(--ads-color-brand);
  color: ${Colors.COD_GRAY};`}
  cursor: pointer;
`;

type PageHeaderProps = {
  user?: User;
  hideShadow?: boolean;
  showSeparator?: boolean;
};

interface tabEventList {
  perms: string;
  menuName: string;
  menuId: number;
}

export function PageHeader(props: PageHeaderProps) {
  const { user } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useIsMobileDevice();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const tenantConfig = useSelector(getTenantConfig);
  let loginUrl = AUTH_LOGIN_URL;
  if (queryParams.has("redirectUrl")) {
    loginUrl += `?redirectUrl
    =${queryParams.get("redirectUrl")}`;
  }

  const featureFlags = useSelector(selectFeatureFlags);
  const menuList = useSelector(selectUserMenuLit);
  useEffect(() => {
    dispatch(getTemplateNotificationSeenAction());
  }, []);

  const tabs = [
    {
      id: 1,
      title: "应用",
      path: APPLICATIONS_URL,
      matcher: [matchApplicationPath],
    },
    // {
    //   id: 2,
    //   title: "模板",
    //   path: TEMPLATES_PATH,
    //   matcher: [matchTemplatesPath, matchTemplatesIdPath],
    // },
    {
      id: 3,
      title: "数据源管理",
      path: TEMPLATES_PATH_DATA + "/datasources/NEW",
      matcher: [matchDataOrigin],
    },
    {
      id: 4,
      title: "数据模型",
      path: TEMPLATES_PATH_DATAModal,
      matcher: [dataModalOrigin],
    },
    {
      id: 5,
      title: "流程管理",
      path: FLOW_MANAGE_PATH,
      matcher: [matchFlowPath],
    },
  ];

  const showTabs = useMemo(() => {
    return tabs.some(
      (tab) => tab.matcher.find((matcher) => matcher(location.pathname)),
      true,
    );
  }, [featureFlags, location.pathname]);
  const [menuId, setMenuId] = useState(0);
  const clickRouter = (tab) => {
    localStorage.setItem("menuId", tab.menuId);
    setMenuId(tab.menuId || localStorage.getItem("menuId"));
    history.push(tab.perms);
  };
  console.log(showTabs, "showTabs");
  console.log(isMobile, "isMobile");

  return (
    <StyledPageHeader
      data-testid="t--appsmith-page-header"
      hideShadow={props.hideShadow || false}
      isMobile={isMobile}
      showSeparator={props.showSeparator || false}
      showingTabs={showTabs}
    >
      <HeaderSection>
        {tenantConfig.brandLogoUrl && (
          <Link className="t--appsmith-logo" to={APPLICATIONS_URL}>
            <img alt="Logo" className="h-6" src={tenantConfig.brandLogoUrl} />
          </Link>
        )}
      </HeaderSection>

      <Tabs>
        {/* showTabs &&  */}
        {!isMobile && (
          <>
            {/* 写死的 */}
            {/* <TabName
              className="t--apps-tab"
              isSelected={matchApplicationPath(location.pathname)}
              onClick={() => history.push(APPLICATIONS_URL)}
            >
              <div>应用</div>
            </TabName>

            <TabName
              className="t--templates-tab"
              isSelected={matchDataOrigin(location.pathname)}
              onClick={() => history.push(TEMPLATES_PATH_DATA)}
            >
              <div>数据源管理</div>
            </TabName>
            <TabName
              className="t--apps-tab"
              isSelected={dataModalOrigin(location.pathname)}
              onClick={() => history.push(TEMPLATES_PATH_DATAModal)}
            >
              <div>数据模型</div>
            </TabName>

            <TabName
              className="t--templates-tab"
              isSelected={matchFlowPath(location.pathname)}
              onClick={() => history.push(FLOW_MANAGE_PATH)}
            >
              <div>流程管理</div>
            </TabName> */}
            {/* 根据后端数据动态加载 */}
            {(menuList || []).map((tab: tabEventList) => (
              <TabName
                // key={tab.menuId}
                className="t--apps-tab"
                isSelected={tab.perms == location.pathname}
                onClick={() => clickRouter(tab)}
              >
                <div>{tab.menuName}</div>
              </TabName>
            ))}
          </>
        )}
      </Tabs>
      <div
        style={{
          color: "#000",
          fontSize: "16px",
          marginRight: "20px",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open(
            "https://xo2e8kb2j9.k.topthink.com/@hxqzicode/didaimajieshao.html",
          );
        }}
      >
        帮助文档
      </div>
      {user && !isMobile && (
        <StyledDropDownContainer>
          {user.username === ANONYMOUS_USERNAME ? (
            <Button
              filled
              intent={"primary"}
              onClick={() => history.push(loginUrl)}
              size="small"
              text="登录"
            />
          ) : (
            <ProfileDropdown
              name={user.name}
              photoId={user?.photoId}
              userName={user.username}
            />
          )}
        </StyledDropDownContainer>
      )}
      {isMobile && !isMobileSidebarOpen && (
        <StyledTwoLineHamburger onClick={() => setIsMobileSidebarOpen(true)} />
      )}
      {isMobile && isMobileSidebarOpen && (
        <Icon
          fillColor={Colors.CRUSTA}
          name="close-x"
          onClick={() => setIsMobileSidebarOpen(false)}
          size={IconSize.XXXXL}
        />
      )}
      {isMobile && user && (
        <MobileSideBar
          isOpen={isMobileSidebarOpen}
          name={user.name}
          userName={user.username}
        />
      )}
    </StyledPageHeader>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: getCurrentUser(state),
  hideShadow: state.ui.theme.hideHeaderShadow,
  showSeparator: state.ui.theme.showHeaderSeparator,
});

export default connect(mapStateToProps)(PageHeader);
