import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from "@appsmith/constants/ApiConstants";
import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type SaveFlowModalResponse = ApiResponse;

export interface SaveFlowRequest {
  pId: string;
  resource: string;
}

class getFlowModalApi extends API {
  static baseURL = "/flow/manage";
  static saveFlow(
    request: SaveFlowRequest,
  ): AxiosPromise<SaveFlowModalResponse> {
    return API.get(
      getFlowModalApi.baseURL +
        "/getProcessDetail/" +
        request.pId +
        "/" +
        request.resource,
    );
  }
}

export default getFlowModalApi;
