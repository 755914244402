import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";
import { result } from "lodash";

const sqlVal: sqlReVal = {
  returnSqlList: {}, // 返回正常数据
  returnString: "", // 返回字符串
  returnAffect: [], // 行数变化
};

const submitSqlReducer = createReducer(sqlVal, {
  [ReduxActionTypes.SUBMIT_SQL_ACTIONS]: (state: sqlReVal, action) => {
    if (typeof action.payload.body === "string") {
      console.log("返回字符串");
      return {
        ...state,
        returnSqlList: {},
        returnString: action.payload.body || "",
      };
    } else if (action.payload.body.length === 0) {
      console.log("无数据");
      return {
        ...state,
        returnSqlList: {},
        returnString: "此表无数据，请自行前往数据库手动添加",
      };
    } else if (
      action.payload.body.length >= 1 &&
      action.payload.body[0].affectedRows !== 0 &&
      !action.payload.body[0].affectedRows
    ) {
      console.log("正常");
      return {
        ...state,
        returnString: "",
        returnSqlList: action.payload || {},
      };
    } else {
      console.log("行数变化");
      return {
        ...state,
        returnString: "",
        returnSqlList: {},
        returnAffect: action.payload.body || [],
      };
    }
  },
  [ReduxActionTypes.CLEAR_DATA_ACTION]: (
    state: sqlReVal,
    action: ReduxAction<[]>,
  ) => {
    return {
      ...state,
      returnSqlList: {},
      returnString: "",
      returnAffect: [],
    };
  },
});

export interface sqlReVal {
  returnSqlList: {};
  returnString: string;
  returnAffect: [];
}

export default submitSqlReducer;
