import React, { Component } from "react";
import LeftTree from "./components/leftTree";
import RightList from "./components/rightList";
import styled from "styled-components";
import { Col, Row } from "antd";

const ModalBox = styled.div`
  margin-top: 48px;
  width: 100%;
`;

export default class DataModal extends Component {
  render() {
    return (
      <ModalBox>
        <Row>
          <Col span={4}>
            <LeftTree></LeftTree>
          </Col>
          <Col span={20} style={{ height: "95vh", overflowY: "auto" }}>
            <RightList></RightList>
          </Col>
        </Row>
      </ModalBox>
    );
  }
}
