import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

const setTreeList: SetTreeReducerState = {
  setTreeList: {},
};

const leftTreeListReducer = createReducer(setTreeList, {
  [ReduxActionTypes.SET_RIGHTTREE_TITLE_ACTION]: (
    state: SetTreeReducerState,
    action: ReduxAction<[]>,
  ) => {
    return {
      ...state,
      setTreeList: action.payload,
    };
  },
});

export interface SetTreeReducerState {
  setTreeList: {};
}

export default leftTreeListReducer;
