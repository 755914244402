import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";

type TreeActionPayload = {
  state: boolean;
  deployName: string;
  body: string;
};

function* getLoadingState(action: ReduxAction<TreeActionPayload>) {
  const { payload } = action;
  yield put({
    type: ReduxActionTypes.LOADING_STATE,
    payload: payload.state,
  });
}

export default function* LoadingSage() {
  yield all([
    takeEvery(ReduxActionTypes.LOADING_STATE_ACTION, getLoadingState),
  ]);
}
