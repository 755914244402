import FlowApi, { SaveFlowResponse } from "api/FlowApi";
import FlowDataListApi, { SaveFlowDataResponse } from "api/FlowDataList";
import getFlowModalApi, { SaveFlowModalResponse } from "api/FlowModalMsg";
import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";
import { message } from "antd";
type FlowActionPayload = {
  deployName: string;
  body: string;
};

type GetFlowData = {
  pageSize: number;
  pageNum: number;
};

type GetModal = {
  pId: string;
  resource: string;
};

function* saveFlow(action: ReduxAction<FlowActionPayload>) {
  const { payload } = action;

  const response: SaveFlowResponse = yield call(FlowApi.saveFlow, payload);
  const isValidResponse: boolean = yield validateResponse(response);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.GET_FLOW_MODALMSG,
      payload: {
        xmlStr: "",
      },
    });
    message.info(response.data);
    yield put({
      type: ReduxActionTypes.GET_FLOW_DATALIST,
      payload: {
        pageSize: 10,
        pageNum: 1,
      },
    });
  } else {
    message.info(response.data);
  }
}

function* getFlowDataList(action: ReduxAction<GetFlowData>) {
  const { payload } = action;

  const response: SaveFlowDataResponse = yield call(
    FlowDataListApi.getFlowDataList,
    payload,
  );
  const isValidResponse: boolean = yield validateResponse(response);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.GET_FLOW_DATALIST_SUCCESS,
      payload: response.data,
    });
  } else {
  }
}

function* getFlowModalMsg(action: ReduxAction<GetModal>) {
  const { payload } = action;

  const response: SaveFlowDataResponse = yield call(
    getFlowModalApi.saveFlow,
    payload,
  );

  const isValidResponse: boolean = yield validateResponse(response);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.GET_FLOW_MODALMSG,
      payload: {
        xmlStr: response.data,
      },
    });
  } else {
  }
}

export default function* flowSagas() {
  yield all([takeEvery(ReduxActionTypes.SAVE_FLOW_ACTIONG, saveFlow)]);

  yield all([takeEvery(ReduxActionTypes.GET_FLOW_DATALIST, getFlowDataList)]);

  yield all([
    takeEvery(ReduxActionTypes.GET_FLOW_MODALMSG_ACTION, getFlowModalMsg),
  ]);
}
