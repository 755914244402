import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type newData = ApiResponse;

export interface SaveNew {
  id: string;
  deployName: string;
  body: string;
}

class newDataListApi extends API {
  // static baseURL = ;
  static saveNewApi(request: SaveNew): AxiosPromise<newData> {
    return API.get(`/v1/applications/new`);
  }
}

export default newDataListApi;
