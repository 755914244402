import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from "@appsmith/constants/ApiConstants";
import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type SaveDeleteXml = ApiResponse;

type deleteXmlPayload = {
  total: string;
  data: [];
};

class deleteXmlApi extends API {
  static baseURL = "/flow/manage/";
  static saveDelete(request: deleteXmlPayload): AxiosPromise<SaveDeleteXml> {
    return API.delete(deleteXmlApi.baseURL + request.id);
  }
}

export default deleteXmlApi;
