import React from "react";
import { useSelector } from "react-redux";

import FooterLinks from "./FooterLinks";
import { getTenantConfig } from "@appsmith/selectors/tenantSelectors";
import "./Container.css";

type ContainerProps = {
  title: string;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  disabledLoginForm?: boolean;
};

function Container(props: ContainerProps) {
  const { children, footer, subtitle, title } = props;
  const tenantConfig = useSelector(getTenantConfig);

  return (
    <>
      <div className="cont-box py-8 px-6 w-[min(400px,80%)] flex flex-col gap-8 t--login-container">
        <div className="flex">
          <img className="w-[50px] h-[50px]" src={tenantConfig.brandLogoUrl} />
          <div className="flex flex-col ml-3">
            <p className="text-xl font-medium" style={{ color: "#265C72" }}>
              {title}
            </p>
            {subtitle && (
              <p className="text-sm" style={{ color: "#265C72" }}>
                {subtitle}
              </p>
            )}
          </div>
        </div>
        {children}
      </div>

      {/* �ײ� */}
    </>
    // <div className="bg-white border w-[min(400px,80%)]">
    //     {footer}
    //     {/* <FooterLinks /> */}
    // </div>
  );
}

export default Container;
