import FlowApi, { SaveFlowResponse } from "api/FlowApi";
import getSignUpApi, { SaveSignUpResponse } from "api/SignUp";
import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";
import { message } from "antd";

type GetUser = {
  email: string;
  password: string;
};

function* signUpSubmit(action: ReduxAction<GetUser>) {
  const { payload } = action;
  console.log(payload);

  const response: SaveSignUpResponse = yield call(
    getSignUpApi.saveSignUp,
    payload,
  );
  const isValidResponse: boolean = yield validateResponse(response);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.SET_SIFINUP_SUCCESS,
      payload: response.data,
    });
  } else {
  }
}

export default function* signupSagas() {
  yield all([takeEvery(ReduxActionTypes.SET_SIGINUP_ACTION, signUpSubmit)]);
}
