import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from "@appsmith/constants/ApiConstants";
import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type SaveFlowDataResponse = ApiResponse;

export interface SaveFlowRequest {
  pageSize: number;
  pageNum: number;
}

class FlowDataListApi extends API {
  static *getFlowModalMsg(
    getFlowModalMsg: any,
    payload: { pageSize: number; pageNum: number },
  ) {
    throw new Error("Method not implemented.");
  }
  static getFlowDataList(_payload: SaveFlowRequest) {
    return API.post(FlowDataListApi.baseURL + "/getProcessLists", {
      ..._payload,
    });
  }
  static baseURL = "/flow/manage";
  static saveFlow(
    request: SaveFlowRequest,
  ): AxiosPromise<SaveFlowDataResponse> {
    return API.post(FlowDataListApi.baseURL + "/getProcessLists", {
      ...request,
    });
  }
}

export default FlowDataListApi;
