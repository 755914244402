import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type sql = ApiResponse;

export interface SaveSql {
  id: string;
  deployName: string;
  body: string;
}

class submitSqlApi extends API {
  static saveTree(request: any): AxiosPromise<sql> {
    return API.post(`/v1/dataModel/_db/execute`, { ...request });
  }
}

export default submitSqlApi;
