import { connect, useSelector } from "react-redux";
import React, { Component, ReactNode } from "react";
import { AppState } from "../../ce/reducers";
import { ReduxActionErrorTypes, ReduxActionTypes } from "../../ce/constants/ReduxActionConstants";
import { Datasource } from "../../entities/Datasource";
import { Workspace, Workspaces } from "../../ce/constants/workspaceConstants";
import { Link, withRouter } from "react-router-dom";
import DatasourceContainer from "./datasource";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { getCurrentAppWorkspace, getCurrentWorkspace } from "../../ce/selectors/workspaceSelectors";

type DatasourceLoaderProps ={
  datasourceList:Datasource[],
  workspaceList: Workspaces[],
  history: any,
  match: any,
  location: any,
  getDatasource8SpaceId: (workspaceId:string)=> void,
  getPluginConfigForm: () => void,
  getDefaultPluginConfig: () => void
  getAllPlugs:(workspaceId:string) => void,
  initPluginDatasource:(workspaceId:string) => void
  currentWorkspace:Workspace
  initApplicationWorkspace:() => void

}

class DatasourceLoader extends Component<DatasourceLoaderProps, any>{
  constructor(props:DatasourceLoaderProps) {
    super(props);
  }


   componentDidMount() {
    const { currentWorkspace, initPluginDatasource, initApplicationWorkspace} = this.props
     let workspaceId =  currentWorkspace.id;
     if (workspaceId){
       initPluginDatasource(workspaceId)
     }else {
       initApplicationWorkspace();
     }
  }
  public render() {
    const {currentWorkspace} = this.props
    let workspaceId =  currentWorkspace.id;
    return (
      <PageWrapper>
        { workspaceId && <DatasourceContainer />}
      </PageWrapper>
    );
  }
}

type PageWrapperProps = {
  children?: ReactNode;
  displayName?: string;
  isFixed?: boolean;
  isSavable?: boolean;
};

const Wrapper = styled.section<{ isFixed?: boolean }>`
  ${(props) =>
  props.isFixed
    ? `margin: 0;
  position: fixed;
  top: ${props.theme.homePage.header}px;
  width: 100%;`
    : `margin-top: ${props.theme.homePage.header}px;`}
  && .fade {
    position: relative;
  }
  && .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  && .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    display: none;
    opacity: 0;
  }
`;

const PageBody = styled.div<{ isSavable?: boolean }>`
  height: calc(
    100vh - ${(props) => props.theme.homePage.header}px - ${(props) =>
  props.isSavable ? "84px" : "0px"}
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding-top: ${(props) => props.theme.spaces[12]}px;
  margin: 0 auto;
  & > * {
    width: 100%;
  }
`;

export function PageWrapper(props: PageWrapperProps) {
  const { isFixed = false, isSavable = false } = props;
  return (
    <Wrapper isFixed={isFixed}>
      <Helmet>
        <title>{`${props.displayName ? `${props.displayName} - ` : ""}
         icode
         `}</title>
      </Helmet>
      <PageBody isSavable={isSavable}>{props.children}</PageBody>
    </Wrapper>
  );
}

const  mapStateToProps = (state:AppState) => ({
  currentWorkspace:getCurrentAppWorkspace(state)
})

const mapDispatchToProps = (dispatch: any)=> ({
  initPluginDatasource:(workspaceId:string) => {
      dispatch({type:ReduxActionTypes.DATASOURCE_MANAGE_INIT, payload:{workspaceId}})
  },
  initApplicationWorkspace:()=> {
    dispatch({type:ReduxActionTypes.GET_ALL_APPLICATION_INIT})
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatasourceLoader));
