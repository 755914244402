import React from "react";
import { Button, Table, message, Drawer, Form, Input, Switch, Tabs } from "antd";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import "codemirror/mode/shell/shell";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/hint/show-hint.css"; // 用来做代码提示
import "codemirror/addon/hint/show-hint.js"; // 用来做代码提示
import "codemirror/addon/hint/sql-hint.js"; // 用来做代码提示
import "./index.css";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { ReduxActionTypes } from "../../../../ce/constants/ReduxActionConstants";
import { AppState } from "ce/reducers";
import type { FormInstance } from 'antd/es/form';
import TableData from './table'
import AddTable from "./AddTable/AddTable";

type sql = {
  submitSQL: (SQL: string, id: string, type: {}) => void;
  getTreeId: (id:any) => void;
  submitSqlList: () => void;
  getTreeListAction: (id: string) => void;
  returnString: string;
  returnAffect: [];
  clearData: () => void;
  getProcessList: []
};

const ErrorBox = styled.div`
  width: 100%;
  height: 460px;
  background-color: #fff;
  color: #f43d3d;
  font-size: 18px;
  padding-top: 10px;
`

const Back = styled.div`
  width: 100%;
    height: 50px;
    background-color: #eeeeee;
    padding: 10px 20px;
    border-radius: 15px 15px 0 0;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
`

const Incidence = styled.div`
  width: 220px;
  height: 150px;
  margin-top: 2px;
  & div {
    font-size: 14px;
    background-color: rgb(238, 238, 238);
    padding: 7px;
    border: 1px solid rgb(238, 238, 238);
  }
  & div:nth-child(2) {
    background-color: #fff;
    border-top: 0;
  }
  & div:nth-child(2):hover {
    background-color: rgb(216, 216, 216);
    cursor: pointer;
  }
`

const Header = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const { TextArea } = Input;

class SqlConsole extends React.Component<sql, any> {
  formRef = React.createRef<FormInstance>();
  constructor(props: sql) {
    super(props);
    this.state = {
      value: ``,
      instance: "",
      columns: [],
      tableSqlItem: {},
      openAddTable: true,
      openTableData: false, // 控制下方table
      propsTableColumns: {},
      transferTreeList: {}, // 给addTable传递数据
      setUpTable: false, // 创建表的按钮
      updataTable: false, // 修改表的按钮
    };
  }

  // 执行SQL
  outSQL = () => {
    const { submitSQL, getTreeId, submitSqlList, getTreeListAction, getProcessList } = this.props;

    if (Object.keys(getTreeId).length === 0) {
      return message.error("请选择要查询的数据源");
    }
    if (!this.state.instance) {
      return message.warning("请输入SQL");
    }
    let queryList = {
      type: 'executeSql',
      listName: getTreeId.id.name,
      listId: getTreeId.id.id,
    }
    // 解构出id
    const { id: { id } } = getTreeId
    getTreeListAction(id)
    let propsTableColumns = {
      type: 'executeSql',
      tableData: getProcessList,
      sql: this.state.instance
    }
    this.setState({ propsTableColumns })
    // 获取数据
    submitSQL(this.state.instance, getTreeId.id.id, queryList);
  };

  static handlerObj = {
    1: (execResult) => {
      let columnResult = [];
      let firstItem = execResult[0];
      if (!firstItem) return;
      let keys = Object.keys(firstItem);
      keys.forEach((key, index) => {
        let obj = {};
        obj["dataIndex"] = key;
        obj["title"] = key;
        obj["key"] = key;
        obj["width"] = 300;
        obj["align"] = "center";
        columnResult.push(obj);
      });

      return {
        hasData: true,
        columns: columnResult,
      };
    },
    0: (execResult) => {
      return {
        hasData: false,
        msg: execResult,
        columns: [],
      };
    },
  };

  // 查看表数据
  checkSurfData = () => {
    // 请求查询sql接口
    const { submitSQL, getTreeId, submitSqlList } = this.props
    if (JSON.stringify(getTreeId) == "{}") {
      return message.warning("请在左侧选择要查询的表")
    }

    const { id: { id, name } } = getTreeId
    const SQL = `SELECT * FROM ${name}`
    // 请求接口
    let queryList = {
      type: 'addField',
      listName: name,
      listId: id,
    }
    submitSQL(SQL, id,queryList)
    // 切换成table
    this.setState({ openTableData: true })
  }

  // 新增表
  addList = () => {
    const { clearData } = this.props
    clearData()
  }

  // 创建表
  addTableList = () => {
    const { getTreeId } = this.props;
    if (Object.keys(this.props.getTreeId).length == 0) return message.warning("请在左侧选择想要添加的数据源")
    this.setState({
      transferTreeList: {
        title: getTreeId.id.title,
        id: getTreeId.id.id,
        typeData: "setupData"
      }
    })
    this.setState({ openTableData: false })
    this.setState({ setUpTable: true })
    this.setState({ updataTable: false })
    this.setState({ openAddTable: false })
    const { clearData } = this.props
    clearData()
  }

  // 修改表
  updataTableList = () => {
    const { getTreeId } = this.props;
    
    if (Object.keys(this.props.getTreeId).length == 0) return message.warning("请在左侧选择想要修改的数据源")
    // 传递数据
    this.setState({
      transferTreeList: {
        title: getTreeId.id.title,
        id: getTreeId.id.id,
        typeData: 'changeData',
        tableList: getTreeId.id.tableList || {}
      }
    })
    this.setState({ openTableData: false })
    this.setState({ openAddTable: false })
    this.setState({ setUpTable: false })
    this.setState({ updataTable: true })

    const { clearData } = this.props
    clearData()
  }

  // 打开编辑器
  openSQL = () => {
    this.setState({ openAddTable: true })
    const { clearData } = this.props
    clearData()
  }

  render() {
    const { submitSqlList, getTreeId, getProcessList } = this.props;
    
    let dataSource = [];
    let columns = [];
    if (submitSqlList.body && Array.isArray(submitSqlList.body)) {
      let firstItem = submitSqlList.body[0];
      submitSqlList.body.map((item) => {
        let key = Object.keys(firstItem);
        let obj = {};
        key.forEach((el) => {
          obj[el] = item[el];
        });
        dataSource.push(obj);
        return {
          dataSource,
        };
      });
    }
    let columnObjs = SqlConsole.handlerObj[
      Number(Array.isArray(submitSqlList.body))
    ](submitSqlList.body);

    if (columnObjs && columnObjs.hasData) {
      columns = columnObjs.columns;
    }
    
    // 渲染数据
    let content;
    if (this.props.returnString) {
      // 渲染错误信息
      content = <ErrorBox>{this.props.returnString}</ErrorBox>
    } else if (Object.keys(submitSqlList).length !== 0) {
      // 渲染正常数据
      content = <TableData tableDataItem={getTreeId} selectData={submitSqlList} tableColumns={this.state.propsTableColumns} />
    } else if (this.props.returnAffect.length !== 0) {
      // 渲染行数变化
      content = <Incidence>
        <div>affectedRows</div>
        <div>{this.props.returnAffect[0].affectedRows}</div>
      </Incidence>
    }
    
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          fontSize: "12px",
          padding: "20px",
        }}
      >
        <Header>
          <div>
            <Button
              type="primary"
              style={{ marginRight: 20 }}
              onClick={this.checkSurfData}
            >
              查看表数据
            </Button>
            <Button type="primary" onClick={this.openSQL} style={{ marginRight: 20 }}>
              打开编辑器
            </Button>
            <Button type="primary" onClick={this.outSQL} style={{ marginRight: 20 }}>
              执行SQL
            </Button>
          </div>

          <div>
            <Button
              type="primary"
              style={{ marginRight: 20 }}
              onClick={this.addTableList}
            >
              创建表
            </Button>
            <Button
              type="primary"
              style={{ marginRight: 20 }}
              onClick={this.updataTableList}
            >
              修改表
            </Button>
          </div>
        </Header>
        {
          this.state.openAddTable ? (
            <>
              <CodeMirror
                value={this.state.value}
                options={{
                  mode: "text/x-mysql",
                  lineNumbers: true, // 显示行号
                  cursorHeight: 1,
                  autofocus: true, //自动获取焦点
                  gutters: ["CodeMirror-linenumbers", "CodeMirrorfoldgutter"],
                }}
                onChange={(editor, data, value) => {
                  this.setState({ instance: value });
                }}
                onInputRead={(editor, change) => {
                  editor.setOption('hintOptions', {
                    completeSingle: false,
                    hint: undefined
                  });
                  editor.execCommand("autocomplete");
                }}
              />
            </>
          ) : (
            <>
              {this.state.setUpTable && <AddTable tableDataItem={this.state.transferTreeList} />}
              {this.state.updataTable && <AddTable tableDataItem={this.state.transferTreeList} />}
            </>
          )
        }
        <Back>
          <p>执行结果</p>
        </Back>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  submitSqlList: state.ui.sql?.returnSqlList, // 正常数据
  returnAffect: state.ui.sql?.returnAffect, // 新增行数据
  returnString: state.ui.sql?.returnString, // 字符串数据
  getTreeId: state.ui.leftTreeList?.setTreeList,
  getProcessList: state.ui.leftTree?.getTreeList, // table头数据
});

const mapDispathToSQL = (dispatch: any) => ({
  submitSQL: (sql: string, data: any, queryList?: any) => {
    dispatch({
      type: ReduxActionTypes.SUBMIT_SQL,
      payload: {
        datasourceId: data,
        executeBody: sql,
        queryList
      },
    });
  },
  // 通过id获取表格头
  getTreeListAction: (id: any) => {
    dispatch({
      type: ReduxActionTypes.GET_LEFTTREE_DISPATH,
      payload: {
        id: id || window.location.pathname.split("/")[2],
      },
    });
  },

  // 清空表格数据的action
  clearData: () => {
    dispatch({
      type: ReduxActionTypes.CLEAR_DATA,
    })
  },

})

export default connect(mapStateToProps, mapDispathToSQL)(SqlConsole);
