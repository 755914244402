import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from "@appsmith/constants/ApiConstants";
import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type SaveFlowResponse = ApiResponse;

export interface SaveFlowRequest {
  deployName: string;
  body: string;
}

class FlowApi extends API {
  static baseURL = "/flow/manage";
  static saveFlow(request: SaveFlowRequest): AxiosPromise<SaveFlowResponse> {
    return API.post(FlowApi.baseURL + "/deployWorkflow", { ...request });
  }
}

export default FlowApi;
