import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

const initialState: FlowReduxState = {
  xmlStr: "",
};

export const datasourceNameReducer = createReducer(initialState, {
  [ReduxActionTypes.GET_FLOW_MODALMSG]: (
    state: FlowReduxState,
    action: ReduxAction<{xmlStr:string}>,
  ) => {
    return {
      ...state,
      xmlStr: action.payload.xmlStr,
    };
  },
});

export interface FlowReduxState {
  xmlStr: string;
}
