import LeftTreeApi, { LeftTree } from "api/LeftTreeApi";
import newDataListApi, { newData } from "api/NewData";
import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";
import { message } from "antd";

type TreeActionPayload = {
  deployName: string;
  body: string;
};

function* saveTree(action: ReduxAction<TreeActionPayload>) {
  const { payload } = action;
  yield put({
    type: ReduxActionTypes.LOADING_STATE_ACTION,
    payload: { state: true },
  });
  const response: LeftTree = yield call(LeftTreeApi.saveTree, payload);
  const isValidResponse: boolean = yield validateResponse(response);
  if (response.data.error && response.data.error.code === 5004)
    return message.error(response.data.error.message);
  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.LOADING_STATE_ACTION,
      payload: { state: false },
    });
    yield put({
      type: ReduxActionTypes.GET_LEFTTREE_ACTION,
      payload: response.data.tables,
    });
  } else {
    message.info(response.data);
  }
}

function* setTreeTitle(action: ReduxAction<TreeActionPayload>) {
  const { payload } = action;
  if (payload) {
    yield put({
      type: ReduxActionTypes.SET_RIGHTTREE_TITLE_ACTION,
      payload: payload,
    });
  }
}

function* getNewData(action: ReduxAction<TreeActionPayload>) {
  const { payload } = action;
  yield put({
    type: ReduxActionTypes.LOADING_STATE_ACTION,
    payload: { state: true },
  });
  const response: newData = yield call(newDataListApi.saveNewApi, payload);
  const isValidResponse: boolean = yield validateResponse(response);

  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.LOADING_STATE_ACTION,
      payload: { state: false },
    });
    yield put({
      type: ReduxActionTypes.GET_NEW_DATALIST_ACTIONS,
      payload: response.data.workspaceDataSources[0],
    });
  }
}

export default function* leftTreeSage() {
  yield all([takeEvery(ReduxActionTypes.GET_LEFTTREE_DISPATH, saveTree)]);
  // 传递title
  yield all([takeEvery(ReduxActionTypes.SET_RIGHTTREE_TITLE, setTreeTitle)]);

  yield all([takeEvery(ReduxActionTypes.GET_NEW_DATALIST, getNewData)]);
}
