import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  notEmptyValidator,
  emailValidator,
  Text,
  TextInput,
  TextType,
  Toaster,
  Variant,
} from "design-system";
import { useDispatch, useSelector } from "react-redux";
import { Classes } from "@blueprintjs/core";
import { getCurrentUser } from "selectors/usersSelectors";
import { forgotPasswordSubmitHandler } from "pages/UserAuth/helpers";
import {
  FORGOT_PASSWORD_SUCCESS_TEXT,
  createMessage,
} from "@appsmith/constants/messages";
import { logoutUser, updateUserDetails } from "actions/userActions";
import { AppState } from "@appsmith/reducers";
import UserProfileImagePicker from "./UserProfileImagePicker";
import {
  Wrapper,
  FieldWrapper,
  LabelWrapper,
  Loader,
  TextLoader,
} from "./StyledComponents";
import { getCurrentUser as refreshCurrentUser } from "actions/authActions";
import { getAppsmithConfigs } from "@appsmith/configs";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import { message } from "antd";
const { disableLoginForm } = getAppsmithConfigs();

const ForgotPassword = styled.a`
  margin-top: 12px;
  border-bottom: 1px solid transparent;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  display: inline-block;
`;

function General() {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const forgotPassword = async () => {
    try {
      await forgotPasswordSubmitHandler({ email: user?.email }, dispatch);
      Toaster.show({
        text: createMessage(FORGOT_PASSWORD_SUCCESS_TEXT, user?.email),
        variant: Variant.success,
      });
      dispatch(logoutUser());
    } catch (error) {
      Toaster.show({
        text: (error as { _error: string })._error,
        variant: Variant.success,
      });
    }
  };

  const [isEditPhone, setIsEditPhone] = useState(false);
  const [mobile, setMobile] = useState("");

  const timeout = 1000;
  const onNameChange = debounce((newName: string) => {
    dispatch(
      updateUserDetails({
        name: newName,
      }),
    );
  }, timeout);
  const phoneChange = debounce(() => {
    /** 手机号码正则 */
    const REGEXP_PHONE = /^[1](([3][0-9])|([4][01456789])|([5][012356789])|([6][2567])|([7][0-8])|([8][0-9])|([9][012356789]))[0-9]{8}$/;
    if (REGEXP_PHONE.test(mobile)) {
      !(mobile === user?.phone) &&
        dispatch(
          updateUserDetails({
            phone: mobile,
          }),
        );
      setIsEditPhone(false);
    } else {
      message.error("请输入正确的手机号");
    }
  }, timeout);

  const editMobile = () => {
    setMobile(user?.phone);
    setIsEditPhone(true);
  };

  const isFetchingUser = useSelector(
    (state: AppState) => state.ui.users.loadingStates.fetchingUser,
  );

  useEffect(() => {
    dispatch(refreshCurrentUser());
  }, []);

  if (user?.email === ANONYMOUS_USERNAME) return null;
  console.log(user);
  return (
    <Wrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Text type={TextType.H4}>头像</Text>
        </LabelWrapper>
        {/* 上传头像组件 */}
        <UserProfileImagePicker />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Text type={TextType.H4}>昵称</Text>
        </LabelWrapper>
        {isFetchingUser && <Loader className={Classes.SKELETON} />}
        {!isFetchingUser && (
          <div style={{ flex: 1 }}>
            <TextInput
              cypressSelector="t--display-name"
              defaultValue={user?.name}
              fill={true}
              onChange={onNameChange}
              placeholder="昵称"
              validator={notEmptyValidator}
            />
          </div>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Text type={TextType.H4}>邮箱</Text>
        </LabelWrapper>
        <div style={{ flexDirection: "column", display: "flex" }}>
          {isFetchingUser && <TextLoader className={Classes.SKELETON} />}
          {!isFetchingUser && <Text type={TextType.P1}>{user?.email}</Text>}

          {!disableLoginForm && (
            <ForgotPassword onClick={forgotPassword}>重置密码</ForgotPassword>
          )}
        </div>
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Text type={TextType.H4}>手机</Text>
        </LabelWrapper>
        <div style={{ flexDirection: "column", display: "flex" }}>
          {isEditPhone ? (
            <TextInput
              cypressSelector="t--display-phone"
              defaultValue={mobile}
              fill={true}
              onChange={(e) => setMobile(e)}
              placeholder="请输入手机号码"
            />
          ) : (
            <Text type={TextType.P1}>{user?.phone || "无"}</Text>
          )}
          {isEditPhone ? (
            <ForgotPassword onClick={phoneChange}>保存</ForgotPassword>
          ) : (
            <ForgotPassword onClick={editMobile}>编辑</ForgotPassword>
          )}
        </div>
      </FieldWrapper>
      {/* <InputWrapper>
        <LabelWrapper>
          <Text type={TextType.H4}>Website</Text>
        </LabelWrapper>
        <TextInput
          placeholder="Your website"
          onChange={() => null}
          defaultValue={""}
          cypressSelector="t--profile-website"
        />
      </InputWrapper> */}
    </Wrapper>
  );
}

export default General;
