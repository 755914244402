import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from "@appsmith/constants/ApiConstants";
import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type SaveSignUpResponse = ApiResponse;

export interface SaveSignUpRequest {
  email: string;
  password: string;
}

class getSignUpApi extends API {
  static baseURL = "/v1";
  static saveSignUp(
    request: SaveSignUpRequest,
  ): AxiosPromise<SaveSignUpResponse> {
    const formData = new FormData();
    formData.append("email", request.email);
    formData.append("password", request.password);
    return API.formPost(getSignUpApi.baseURL + "/users", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      transformRequest: (data) => {
        return `email=${request.email}&password=${request.password}`;
      },
    });
  }
}

export default getSignUpApi;
