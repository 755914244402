import React, { Component } from 'react';
import { Pagination, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Button, Tooltip } from 'antd';
import Modal from './components/Modal/Modal';
import { connect, useDispatch } from "react-redux";
import { ReduxActionTypes } from "../../ce/constants/ReduxActionConstants";
import { AppState } from 'ce/reducers';
import { FlowInfo } from 'reducers/uiReducers/FlowGetDataRedux';
import styled from "styled-components";
const Pagina = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

interface DataType {
  key: string;
  name: string;
  age: string;
  address: string;
}


type FlowProps = {
  listProcess: (num, size) => void
  getProcessList: () => void
  getProcess: (pId: string, resource: string) => void
  clearXml: () => void
  deleteXml: (id: string) => void
}
class Flow extends Component<FlowProps, any> {

  constructor(props: FlowProps) {
    super(props)
    this.state = {
      setModal: false
    }
  }

  changeModal = () => {
    const { clearXml } = this.props
    clearXml()
    this.setState({ setModal: true })
  }
  getModalMsg = (row: FlowInfo) => {
    this.props.getProcess(row.deploymentId, row.resourceName)
    this.setState({ setModal: true })
  }

  componentDidMount() {
    const { listProcess } = this.props
    listProcess(1, 10)
  }

  // 删除流程
  deleteModalMsg = (e,row) => {
    e.stopPropagation()
    const { deleteXml } = this.props
    deleteXml(row.deploymentId)
    this.setState({ setModal: false })
  }

  // 分页器
  pagina = (page, pageSize) => {
    const { listProcess } = this.props
    listProcess(page, pageSize)
  }


  render() {
    const columns: ColumnsType<FlowInfo> = [
      {
        title: '流程名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '流程Key',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '流程id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record: FlowInfo) => (
          <Space size="middle">
            <Button type="link" onClick={() => this.getModalMsg(record)}>查看</Button>
            <Popconfirm
              placement="bottomLeft"
              title={"你确定要删除该流程图吗"}
              onConfirm={(e) => this.deleteModalMsg(e,record)}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link" style={{ marginLeft: 20 }}>删除</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];


    const { getProcessList } = this.props
    let flowList = getProcessList()

    return (
      <div style={{ margin: '55px' }}>
        <Button type="primary" shape="default" style={{ margin: '30px 0' }} onClick={this.changeModal}>在线绘制流程</Button>
        <Table columns={columns} dataSource={flowList.data} scroll={{ x: '100%', y: 650 }} bordered={true} pagination={false} />
        <Pagina>
          <Pagination defaultCurrent={1} total={flowList.total} onChange={this.pagina} pageSizeOptions={[10, 20, 50]} />
        </Pagina>
        <Modal setModal={this.state.setModal} destroyOnClose></Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  getProcessList: (): FlowInfo[] => state.ui.flow.flowList
})

const mapDispatchToProps = (dispatch: any) => ({
  empty: () => {
    dispatch({
      type: ReduxActionTypes.EMPTU_FLOW_XML
    })
  },
  listProcess: (num, Size) => {
    dispatch({
      type: ReduxActionTypes.GET_FLOW_DATALIST,
      payload: {
        pageSize: Size,
        pageNum: num,
      },
    })
  },

  // 清空流程图
  clearXml: () => {
    dispatch({
      type: ReduxActionTypes.GET_FLOW_MODALMSG,
      payload: {
        xmlStr: "",
      },
    });
  },

  getProcess: (pId: String, resource: string) => {
    dispatch({
      type: ReduxActionTypes.GET_FLOW_MODALMSG_ACTION,
      payload: {
        pId,
        resource
      },
    })
  },

  // 删除流程图
  deleteXml: (id: string) => {
    dispatch({
      type: ReduxActionTypes.DELETE_FLOW_XML,
      payload: {
        id
      }
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Flow)
