import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ReduxActionTypes } from "../../../../../ce/constants/ReduxActionConstants";
import { Col, Form, Input, Row, Select, Checkbox, Table, Button, AutoComplete, message, Tooltip } from "antd";
import type { FormInstance } from 'antd/es/form';
import type { InputRef } from 'antd'
import type { SelectProps } from 'antd';
import { get_ddl_sql_builder } from './utils_ddl_sql_command_builder.js'

const HeaderInput = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

const FooterBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`

const { Option } = Select;


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="请输入" />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '20px' }} onClick={toggleEdit}>
                <p style={{ width: '100%', height: "100%" }}>{children}</p>
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    fieldName: string,
    fieldType: string,
    majorKey: boolean,
    noNull: boolean,
    remark: string,
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function AddTable(props) {
    const [dataSource, setDataSource] = useState<DataType[]>([
        {
            key: '0',
            fieldName: 'id',
            fieldType: '',
            majorKey: false,
            noNull: false,
            remark: '',
        },
    ]);
    const [disbaled, setDisbaled] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const getTreeList = useSelector(state => state.ui.leftTree)
    console.log(getTreeList);
    
    const [treelist, setTreeList] = useState({})
    useEffect(() => {
        let tableDataList = props.tableDataItem.tableList
        // 点击保存拿到最新数据
        if (!tableDataList) return
        getTreeList.getTreeList.map(el => {
            if (el.name === tableDataList.name) {
                setTreeList(el)
                console.log(el);
                
            }
        })
    }, [getTreeList])
    
    useEffect(() => {
        // 如果存在
        if (props.tableDataItem.typeData === "changeData") {
            let tableDataList = props.tableDataItem.tableList
            const headerData = props.tableDataItem.tableList;
            heaerForm.setFieldsValue({
                filedName: headerData.name,
                characterSet: headerData.tableCollation,
            });
            
            setDisbaled(true)
            // 存储字段信息
            let tableKeys = []
            let newList = tableDataList
            
            if (Object.keys(treelist).length !== 0) {
                console.log(treelist);
                newList = treelist
            }

            // 存储主键
            if (Object.keys(props.tableDataItem.tableList).length === 0) return message.warning("请在左侧选择想要修改的表")
            let keys = props.tableDataItem.tableList.keys[0].columnNames[0]
            newList.columns.forEach(el => {
                let obj = {}
                obj["key"] = el.name,
                    obj["fieldName"] = el.name,
                    obj["fieldType"] = el.type,
                    obj["majorKey"] = keys == el.name ? true : false,
                    obj["noNull"] = el.nullAble == 0 ? true : false,
                    obj["remark"] = el.comment
                obj['check'] = 1
                tableKeys.push(obj)
            });
            setDataSource(tableKeys)
            tableKeys.forEach(i => {
                if (i.majorKey) {
                    setSelectedRowKey(i.key)
                }
            })

        } else {
            setDisbaled(false)
        }
    }, [treelist]) 


    // 数据有变化按钮才能用
    const [hasChanges, setHasChanges] = useState(false);
    useEffect(() => {
        setHasChanges(true);
    }, [dataSource]);


    const [majorKey, setmajorKey] = useState(false)

    const [count, setCount] = useState(2);

    const dispatch = useDispatch()

    // 下拉菜单数据
    const options: SelectProps['options'] = [
        {
            label: '常用数据类型',
            options: [
                { label: 'BIGINT(22)', value: 'BIGINT(22)' },
                { label: 'INT(11)', value: 'INT(11)' },
                { label: 'VARCHAR(191)', value: 'VARCHAR(191)' },
                { label: 'DATETIME', value: 'DATETIME' },
                { label: 'DECIMAL()', value: 'DECIMAL()' },
                { label: 'TEXT', value: 'TEXT' },
            ],
        },
        {
            label: '字符类型',
            options: [
                { label: 'VARCHAR()', value: 'VARCHAR()' },
                { label: 'TINYTEXT', value: 'TINYTEXT' },
                { label: 'MEDUIMTEXT', value: 'MEDUIMTEXT' },
                { label: 'TEXT', value: 'STRINGTEXT' },
                { label: 'LONGTEXT', value: 'LONGTEXT' },
            ],
        },
        {
            label: '数字类型',
            options: [
                { label: 'INT()', value: 'INT()' },
                { label: 'BIGINT()', value: 'BIGINT()' },
                { label: 'TINYINT()', value: 'TINYINT()' },
                { label: 'DECIMAL()', value: 'DECIMAL()' },
                { label: 'SMALLINT()', value: 'SMALLINT()' },
                { label: 'MEDIUMINT()', value: 'MEDIUMINT()' },
                { label: 'FLOAT()', value: 'FLOAT()' },
                { label: 'DOUBLE()', value: 'DOUBLE()' },
            ],
        },
        {
            label: '日期类型',
            options: [
                { label: 'DATETIME', value: 'DATETIME' },
                { label: 'DATE', value: 'DATE' },
                { label: 'TIME', value: 'TIME' },
                { label: 'YEAR', value: 'YEAR' },
            ],
        },
        // {
        //     label: '二进制类型',
        //     options: [
        //         { label: 'TITYBLOB', value: 'TITYBLOB' },
        //         { label: 'BLOB', value: 'BLOB' },
        //         { label: 'MEDUIMTEXT', value: 'MEDUIMTEXT' },
        //         { label: 'LONGBLOB', value: 'LONGBLOB' },
        //     ],
        // },
    ];

    const [statusType, setStatusType] = useState('')

    // table头部
    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: '字段名称',
            dataIndex: 'fieldName',
            editable: true,
        },
        {
            title: '字段类型',
            dataIndex: 'fieldType',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Input.Group compact>
                        <AutoComplete
                            status={statusType}
                            defaultValue={record.fieldType}
                            style={{ width: '70%' }}
                            placeholder="请选择字段类型"
                            options={options}
                            onChange={(e) => blueType(e, record)}
                        />
                    </Input.Group>
                ) : null,
        },
        {
            title: '主键',
            dataIndex: 'majorKey',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Checkbox
                        checked={record.key === selectedRowKey}
                        disabled={disbaled}
                        onChange={(e) => handleKey(e, record)}></Checkbox>
                ) : null,
        },
        {
            title: '非空',
            dataIndex: 'noNull',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Checkbox defaultChecked={record.noNull} onChange={(e) => handleNoNull(e, record)}></Checkbox>
                ) : null,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            editable: true,
        },
    ];

    // 字段类型方法
    const blueType = (e, row) => {
        const type = [...dataSource]
        const index = type.findIndex(item => row.key === item.key)
        const item = type[index]
        item.fieldType = e
        type.splice(index, 1, {
            ...item,
            ...row
        })
        setDataSource(type);
    }

    // 非空多选方法
    const handleNoNull = (e, row) => {
        const checked = [...dataSource]
        const index = checked.findIndex(item => row.key === item.key)
        const item = checked[index]
        item.noNull = e.target.checked
        checked.splice(index, 1, {
            ...item,
            ...row
        })
        setDataSource(checked);
    };

    // 主键多选方法
    const [selectedRowKey, setSelectedRowKey] = useState(null);

    const handleKey = (e, row) => {
        if (e.target.checked) {
            setSelectedRowKey(row.key);
        } else {
            setSelectedRowKey(null);
        }

        const checked = [...dataSource]
        const index = checked.findIndex(item => row.key === item.key)
        const item = checked[index]
        item.majorKey = e.target.checked
        checked.splice(index, 1, {
            ...item,
            ...row
        })

        setDataSource(checked);
    };

    // 新增数据行
    const handleAdd = () => {
        const newData: DataType = {
            key: count,
            fieldName: '字段名称',
            fieldType: '',
            majorKey: false,
            noNull: false,
            remark: '',
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    // 删除数据行
    const handleDelete = () => {
        const oldDataSourch = dataSource
        selectedRowKeys.forEach(el => {
            const index = oldDataSourch.findIndex(item => item.key === el)
            if(index === -1) return
            oldDataSourch.splice(index, 1)
        })
        setDataSource([...oldDataSourch])
    }

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    // 封装执行sql的接口
    const getSql = (sql: string, id: string, queryList?: Object) => {
        dispatch({
            type: ReduxActionTypes.SUBMIT_SQL,
            payload: {
                datasourceId: id,
                executeBody: sql,
                queryList
            }
        })
    }

    // 保存
    const [heaerForm] = Form.useForm();
    const form = useContext(EditableContext);
    const submitAdd = () => {
        const { tableDataItem } = props
        const { tableDataItem: { typeData } } = props
        // 如果是setupData说明是新增表
        if (typeData === "setupData") {
            heaerForm.validateFields().catch(err => {
                return
            })
            // form.getFieldsValue() 获取到的上面一栏的数据
            let title = heaerForm.getFieldsValue(true)
            // id 获取到的表id title name
            const { tableDataItem: { id } } = props
            let majorKeyName = ''
            let SQL = ''
            let sqlList = ''
            let type = dataSource.map(el => {
                if (el.fieldType == '') {
                    return false
                }
                // 如果有noNull就替换成 NOT NULL
                el.noNull == true ? el.noNull = 'NOT NULL' : el.noNull = ''
                // 获取主键name
                if (el.majorKey) majorKeyName = el.fieldName
                // 过滤对象上的 key
                const res = Object.entries(el).filter(([key, val]) => key != "key")
                let values = Object.values(Object.fromEntries(res))
                let strId = "`" + [values[0]].join('') + "` "
                let strReamrk = " COMMENT'" + [values[4]].join('') + "'"
                let strType = [values[1], values[3]].join(' ')
                sqlList = `${strId}${strType}${strReamrk},${sqlList}`
            })

            if (type[0] == false) return setStatusType('error')
            setStatusType('')

            if (dataSource.length == 1) {
                // 拼接SQL
                SQL = `CREATE TABLE ${title.filedName}(${sqlList}PRIMARY KEY (${majorKeyName}) USING BTREE)ENGINE=InnoDB DEFAULT CHARSET=${title.characterSet} COMMENT='${title.remark}'`
            } else {
                // 拼接SQL
                SQL = `CREATE TABLE ${title.filedName}(${sqlList}PRIMARY KEY (${majorKeyName}) USING BTREE)ENGINE=InnoDB DEFAULT CHARSET=${title.characterSet} COMMENT='${title.remark}'`
            }
            let queryList = {
                type: 'addField',
                listName: tableDataItem.name,
                listId: tableDataItem.id,
            }
            getSql(SQL, tableDataItem.id, queryList)
        } else {
            heaerForm.validateFields().catch(err => {
                return
            })
            let header_title = heaerForm.getFieldsValue(true)
            let title = tableDataItem.tableList.name // 表名
            let old_rows = treelist.columns // 旧的字段信息
            let new_rows = dataSource // 新的字段信息
            let old_table = title // 旧的表名
            let new_table = title // 新的表名
            let new_table_charset = header_title.characterSet // 字符集
            let new_table_remarks = header_title.remark // 表备注
            // 获取主键
            let key = treelist.keys[0].columnNames[0] 
            
            // 判断新值的字段跟老值的字段是否相等 判断是否新增行
            new_rows.forEach((el, index) => {
                let old_rows_key = old_rows.findIndex(el => el.name === key)
                console.log(old_rows_key,"ley");
                
                old_rows[old_rows_key].is_primary_key = true  // 当前字段是主键加入 is_primary_key = true
                el.fieldName !== old_rows[index] ? el.options_type = { value: 1 } : el.options_type = undefined // 如果是修改了字段 就加入data_options = {value:1}
                // 判断是否新增行
                if (dataSource.length > old_rows.length) {
                    let addVal = extractExtraObjects(dataSource, old_rows)
                    addVal.forEach(item => {
                        item.options_type = { value: 3 }
                    });
                }
            })

            let type = dataSource.map((el, i) => {
                if (el.fieldType == '') {
                    return false
                }
            })
            if (type[0] == false) return setStatusType('error')
            setStatusType('')
            let sql = get_ddl_sql_builder(2, old_rows, new_rows, old_table, new_table, new_table_charset, new_table_remarks)
            let queryList = {
                type: 'addField',
                listName: tableDataItem.id,
                listId: tableDataItem.tableList.name,
            }
            getSql(sql, tableDataItem.id, queryList)
        }
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const [selectionType, setSelectionType] = useState("checkbox");
    const rowSelection = {
        renderCell(checked, record, index, node) {
            if (record.check === 1) {
                return <Tooltip title="该字段不允许选择"></Tooltip>
            }
            return node;
        },
        getCheckboxProps: (record) => {
            let checkboxProps = {};
            if (record.check === 1) {
                checkboxProps.disabled = true;
            }
            return checkboxProps;
        },
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const extractExtraObjects = (array1, array2) => {
        const extraObjects = array1.length > array2.length ? array1.slice(array2.length) : array2.slice(array1.length);
        return extraObjects;
    }



    return (
        <>
            <HeaderInput>
                <Form
                    ref={heaerForm}
                    form={heaerForm}
                    disabled={disbaled}
                    name="basic"
                    initialValues={{}}
                    onFinish={submitAdd}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginRight: 10 }}
                                label="表名"
                                name="filedName"
                                rules={[{ required: true, message: "请输入表名" }]}
                            >
                                <Input placeholder="请输入表名" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginLeft: 20 }} label="执行引擎">
                                <div>InnoDB</div>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginRight: 10 }}
                                label="字符集"
                                name="characterSet"
                                rules={[{ required: true, message: "请选择字符集" }]}
                            >
                                <Select placeholder="请选择字符集" allowClear>
                                    <Option value="utf8mb4">utf8mb4</Option>
                                    <Option value="utf8">utf8</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginRight: 10 }} label="备注" name="remark">
                                <Input placeholder="请输入备注" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </HeaderInput>
            <div>
                <Table
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection
                    }}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    scroll={{ y: 360 }}
                    dataSource={dataSource}
                    columns={columns as ColumnTypes}
                />
                <FooterBtn>
                    <Button onClick={handleDelete}>
                        删除
                    </Button>
                    <Button onClick={handleAdd} style={{ marginLeft: 20 }}>
                        新增
                    </Button>
                    <Button onClick={submitAdd} type="primary" style={{ marginLeft: 20 }} form="form"
                        htmlType="submit" disabled={!hasChanges}>
                        保存
                    </Button>
                </FooterBtn>
            </div>
        </>
    );
}

export default AddTable;
