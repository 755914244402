import React, {useEffect, useState} from "react";
import IntegrationsHomeScreen from "./IntegrationsHomeScreen";
import { RouteComponentProps } from "react-router";
import * as Sentry from "@sentry/react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentAppWorkspace} from "../../../ce/selectors/workspaceSelectors";
import {ReduxActionTypes} from "../../../ce/constants/ReduxActionConstants";
import {AppState} from "../../../ce/reducers";

type Props = RouteComponentProps<{
  pageId: string;
  selectedTab: string;
}>;

const integrationsEditor = (props: Props) => {
  const { history, location, match } = props;
  const dispatch = useDispatch();
  const workspace = useSelector(getCurrentAppWorkspace);
 // 当获取完工作区间并且未初始化的状态下才进行获取数据原操作
  useEffect(()=> {
    dispatch({
      type:ReduxActionTypes.FETCH_DATASOURCES_INIT,
      payload:{
        workspaceId: workspace.id
      }
    })
  },[])
  const datasourceList = useSelector((state:AppState) => state.entities.datasources.list);
  const plugins = useSelector((state:AppState) => state.entities.plugins.list);
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      { datasourceList && plugins.length  ? (<IntegrationsHomeScreen
        history={history}
        location={location}
        selectedTab={match.params.selectedTab}
      />):<div></div>}
    </div>
  );
};

export default Sentry.withProfiler(integrationsEditor);
