import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import EditorsRouter from "./routes";
import { TEMPLATES_PATH_DATA } from "constants/routes";
import { routeChanged } from "actions/focusHistoryActions";
import { AppsmithLocationState } from "utils/history";

const SentryRoute = Sentry.withSentryRouting(Route);

const Container = styled.div`
  display: flex;
  height: calc(
    100vh - ${(props) => props.theme.smallHeaderHeight} -
      ${(props) => props.theme.bottomBarHeight}
  );
  background-color: ${(props) => props.theme.appBackground};
`;

function DatasourceContainer() {
  const dispatch = useDispatch();

  const location = useLocation<AppsmithLocationState>();

  useEffect(() => {
    dispatch(routeChanged(location));
  }, [location.pathname, location.hash]);
  return (
    <Container className="relative w-full overflow-x-hidden">
      <div
        className="relative flex flex-col w-full overflow-auto"
        id="app-body"
      >
        <Switch key={TEMPLATES_PATH_DATA}>
          <SentryRoute component={EditorsRouter} />
        </Switch>
      </div>
    </Container>
  );
}

DatasourceContainer.displayName = "DatasourceContainer";

export default DatasourceContainer;
