import sunbmitSqlApi, { sql } from "api/submitSqlApi";
import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";
import { message } from "antd";

type TreeActionPayload = {
  datasourceId: any;
  deployName: string;
  body: string;
  queryList: quertList;
};

type quertList = {
  type: string;
  listName: string;
  listId: string;
};

function* submitSQL(action: ReduxAction<TreeActionPayload>) {
  const { payload } = action;
  const response = yield call(sunbmitSqlApi.saveTree, payload);
  const isValidResponse: boolean = yield validateResponse(response);

  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.SUBMIT_SQL_ACTIONS,
      payload: response.data,
    });
    if (typeof response.data.body === "string") return;
    if (
      (payload.queryList && payload.queryList.type === "addField") ||
      payload.queryList.type === "executeSql"
    ) {
      yield put({
        type: ReduxActionTypes.GET_LEFTTREE_DISPATH,
        payload: {
          id: payload.datasourceId,
        },
      });
      yield put({
        type: ReduxActionTypes.LOADING_STATE,
        payload: {
          id: { loading: false },
        },
      });
      return;
    }

    if (
      payload.queryList.type === "updata" ||
      payload.queryList.type === "delete" ||
      payload.queryList.type === "addList"
    )
      return;
    yield querySqlList(payload.queryList);
  }
}

// 执行查询SQL的方法
function* querySqlList(query) {
  if (!query) return;
  let queryList = {
    datasourceId: query.listId,
    executeBody: `SELECT * FROM ${query.listName}`,
  };
  const response: sql = yield call(sunbmitSqlApi.saveTree, queryList);
  const isValidResponse: boolean = yield validateResponse(response);

  if (isValidResponse) {
    yield put({
      type: ReduxActionTypes.SUBMIT_SQL_ACTIONS,
      payload: response.data,
    });
  }
}

export default function* leftTreeSage() {
  yield all([takeEvery(ReduxActionTypes.SUBMIT_SQL, submitSQL)]);
}
