import React, { useEffect, ReactNode, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation, useRouteMatch } from "react-router";
import IntegrationEditor from "./IntegrationEditor";
import DataSourceEditor from "./DataSourceEditor";
import {
  INTEGRATION_EDITOR_PATH,
  DATA_SOURCES_EDITOR_ID_PATH,
  matchBuilderPath,
} from "constants/routes";
import styled from "styled-components";
import { useShowPropertyPane } from "utils/hooks/dragResizeHooks";
import { closeAllModals } from "actions/widgetActions";
import { useDispatch, useSelector } from "react-redux";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";
import * as Sentry from "@sentry/react";
const SentryRoute = Sentry.withSentryRouting(Route);
import { useWidgetSelection } from "utils/hooks/useWidgetSelection";
import { builderURL } from "RouteBuilder";
import history from "utils/history";
import { getCurrentPageId } from "selectors/editorSelectors";

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (!props.isVisible ? "0px" : "100%")};
  height: 100%;
  background-color: ${(props) =>
    props.isVisible ? "rgba(0, 0, 0, 0.26)" : "transparent"};
  z-index: ${(props) => (props.isVisible ? 2 : -1)};
`;

const DrawerWrapper = styled.div<{
  isVisible: boolean;
}>`
  background-color: white;
  width: ${(props) => (!props.isVisible ? "0" : "100%")};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

function EditorsRouter() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = React.useState(
    () => !matchBuilderPath(pathname),
  );

  useEffect(() => {
    const isOnBuilder = matchBuilderPath(pathname);
    setIsVisible(!isOnBuilder);
  }, [pathname]);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      PerformanceTracker.startTracking(
        PerformanceTransactionName.CLOSE_SIDE_PANE,
        { path: pathname },
      );
      e.stopPropagation();
      setIsVisible(false);
    },
    [pathname],
  );

  const preventClose = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);
  return (
    <PaneDrawer isVisible={isVisible} onClick={preventClose}>
      <Switch key={path}>
        <SentryRoute
          component={IntegrationEditor}
          exact
          path={`${path}${INTEGRATION_EDITOR_PATH}`}
        />
        <SentryRoute
          component={DataSourceEditor}
          exact
          path={`${path}${DATA_SOURCES_EDITOR_ID_PATH}`}
        />
      </Switch>
    </PaneDrawer>
  );
}

type PaneDrawerProps = {
  isVisible: boolean;
  onClick: (e: React.MouseEvent) => void;
  children: ReactNode;
};
function PaneDrawer(props: PaneDrawerProps) {
  const showPropertyPane = useShowPropertyPane();
  const { focusWidget, selectWidget } = useWidgetSelection();
  const dispatch = useDispatch();
  useEffect(() => {
    // This pane drawer is only open when NOT on canvas.
    // De-select all widgets
    // Un-focus all widgets
    // Hide property pane
    // Close all modals
    if (props.isVisible) {
      showPropertyPane();
      dispatch(closeAllModals());
      // delaying setting select and focus state,
      // so that the focus history has time to store the selected values
      setTimeout(() => {
        selectWidget(undefined);
        focusWidget(undefined);
      }, 0);
    }
  }, [dispatch, props.isVisible, selectWidget, showPropertyPane, focusWidget]);
  return <DrawerWrapper {...props}>{props.children}</DrawerWrapper>;
}

PaneDrawer.displayName = "PaneDrawer";

export default EditorsRouter;
