import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

const newDataList: GetDataListReducerState = {
  newDataList: [],
};

const getDataListReducer = createReducer(newDataList, {
  [ReduxActionTypes.GET_NEW_DATALIST_ACTIONS]: (
    state: GetDataListReducerState,
    action: ReduxAction<[]>,
  ) => {
    return {
      ...state,
      newDataList: action.payload,
    };
  },
});

export interface GetDataListReducerState {
  newDataList: [];
}

export default getDataListReducer;
