import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

export type TreeBack = {
  favorite: boolean;
  name: string;
};

const initialState: GetLeftTreeReducerState = {
  getTreeList: [],
};

const leftTreeReducer = createReducer(initialState, {
  [ReduxActionTypes.GET_LEFTTREE_ACTION]: (
    state: GetLeftTreeReducerState,
    action: ReduxAction<TreeBack[]>,
  ) => {
    return {
      ...state,
      getTreeList: action.payload,
    };
  },
});

export interface GetLeftTreeReducerState {
  getTreeList: TreeBack[];
}

export default leftTreeReducer;
