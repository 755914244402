import API from "api/Api";
import { ApiResponse } from "./ApiResponses";
import { AxiosPromise } from "axios";

export type LeftTree = ApiResponse;

export interface SaveTree {
  id: string;
  deployName: string;
  body: string;
}

class LeftTreeApi extends API {
  // static baseURL = ;
  static saveTree(request: SaveTree): AxiosPromise<LeftTree> {
    return API.get(`v1/datasources/${request.id}/structure?ignoreCache=true`);
    // return API.get(
    //   `v1/datasources/64b8fb0fb45f1d4ec8af8a97/structure?ignoreCache=true`,
    // );
  }
}

export default LeftTreeApi;
