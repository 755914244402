import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

export type FlowInfo = {
  key: string;
  name: string;
  age: string;
  address: string;
  deploymentId: string;
  resourceName: string;
};

const initialState: FlowGetDataReduxState = {
  flowList: [],
};

const flowReducer = createReducer(initialState, {
  [ReduxActionTypes.SAVE_FLOW_ACTIONG_SUCCESS]: (
    state: FlowGetDataReduxState,
    action: ReduxAction<FlowInfo[]>,
  ) => {
    return {
      ...state,
      flowList: action.payload,
    };
  },
  [ReduxActionTypes.GET_FLOW_DATALIST_SUCCESS]: (
    state: FlowGetDataReduxState,
    action: ReduxAction<any>,
  ) => {
    return {
      ...state,
      flowList: action.payload,
    };
  },
});

export interface FlowGetDataReduxState {
  flowList: FlowInfo[];
}

export default flowReducer;
