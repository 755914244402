import React, { Component } from "react";
import {
  AppstoreOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { Tree, Spin } from 'antd';
import type { DataNode } from 'antd/es/tree';
import styled from "styled-components";
import { AppState } from 'ce/reducers';
import { connect, useDispatch } from "react-redux";
import { TreeBack } from 'reducers/uiReducers/leftTreeReducer';
import { ReduxActionTypes } from "../../../../ce/constants/ReduxActionConstants";
import { Link } from "react-router-dom";

const LeftTreeBox = styled.div`
  background-color: '#000'
  width: 100%;
  height: 95vh;
  border-right: 1px solid #e8e8e8;
  padding-top: 20px;
  padding-left: 15px;
  overflow: scroll;

  & p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
`
const DataBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const IconDiv = styled.div`
  display: flex;
  align-items: center;
`
const TreeNode = Tree.TreeNode;
const renderTreeBox = (data: any) => {
  if (data) {
    return data.map((item: any) => {

      if (item.length === 0) return
      // item.title = `(
      //   <IconDiv>
      //     <p style={{ marginRight: 8, marginBottom: 5 }}><AppstoreOutlined style={{color:"#52c41a",fontSize:18}}/></p>
      //     <p>{item.title}</p>
      //   </IconDiv>
      // )`
      if (item.child && item.child.length > 0) {
        return (
          <TreeNode title={item.title} key={item.key} id={item.id}>
            {renderTreeBox(item.child)}
          </TreeNode>
        )
      } else {
        return (
          <TreeNode title={item.title} key={item.key} id={item.id}></TreeNode>
        )
      }
    })
  }
}


type LeftTree = {
  getTreeListAction: (id: string) => void
  getProcessList: []
  loading: boolean
  onSelectTitle: ({ }) => void
  getNewData: () => void
  getNewDataList: {}
  clearData: () => void
}

class DataModal extends Component<LeftTree, any> {
  constructor(props: LeftTree) {
    super(props)
    this.state = {
      treeId: 0,
      loading: false
    }
  }

  componentDidMount() {
    const { getTreeListAction, getNewData } = this.props
    getTreeListAction('')
    getNewData()
  }

  childrenTree = (selectedKeys: any, e: any, f: any) => {
    const { getTreeListAction, getProcessList, clearData } = this.props
    const { onSelectTitle } = this.props
    getTreeListAction(e.node.id)
    this.setState({ treeId: e.node.id })
    let select = {
      id: e.node.id,
      name: e.node.title,
      title: f[0].title,
      isShow: false
    }
    onSelectTitle(select)
    if (!getProcessList) return
    getProcessList.forEach((el: any) => {
      if (el.name == e.node.title) {
        select['tableList'] = el
      }
    })
    clearData()
  }

  render() {
    const { getProcessList } = this.props
    const { getNewDataList } = this.props
    let getTreeDataList = getProcessList
    let { datasourceList } = getNewDataList // 数据源
    const { loading } = this.props
    

    let treeChild = []
    let key = 0
    let keyB = 1000
    let keyItem = 10000
    if (datasourceList) {
      let MySql = datasourceList.filter(el => el.pluginName === 'MySQL')

      treeChild = MySql.map(item => {
        keyB++
        return {
          id: item.id,
          key: keyB,
          title: item.name,
          child: !getTreeDataList ? [] : getTreeDataList.map(el => {
            if (item.id != this.state.treeId) return []
            key++
            return {
              title: el.name,
              key: key,
              id: item.id,
              child: el.columns.map(res => {
                keyItem++
                return {
                  id: item.id,
                  key: keyItem,
                  title: res.name
                }
              })
            }
          })
        }

      })
    }
    return (
      <LeftTreeBox>
        {
          datasourceList && datasourceList.length === 0 ? (
            <>
              <p>当前无可用数据源</p>
              <p>请进入<Link to={{ pathname: '/datasourceManage/datasources/NEW' }}>数据源管理</Link>进行添加</p>
            </>
          ) : (
            <>
              <Spin spinning={loading}>
                <Tree
                  fieldNames={{ key: "key" }}
                  switcherIcon={<CaretDownOutlined />}
                  onSelect={(e, d) => this.childrenTree(e, d, treeChild)}
                  showLine={true}
                >
                  {renderTreeBox(treeChild)}
                </Tree>
              </Spin>
            </>
          )
        }

      </LeftTreeBox>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  getProcessList: state.ui.leftTree?.getTreeList,
  getNewDataList: state.ui.newData?.newDataList,
  loading: state.ui.loading?.loading
})

const mapDispathToTree = (dispatch: any) => ({
  getTreeListAction: (id: any) => {
    dispatch({
      type: ReduxActionTypes.GET_LEFTTREE_DISPATH,
      payload: {
        id: id || window.location.pathname.split('/')[2]
      }
    })
  },
  // 点击给右侧传递title
  onSelectTitle: (id: string) => {
    dispatch({
      type: ReduxActionTypes.SET_RIGHTTREE_TITLE,
      payload: {
        id
      }
    })
  },
  // 获取new接口中的已保存数据库
  getNewData: () => {
    dispatch({
      type: ReduxActionTypes.GET_NEW_DATALIST
    })
  },

  // 清空表格数据的action
  clearData: () => {
    dispatch({
      type: ReduxActionTypes.CLEAR_DATA,
    })
  },
})


export default connect(mapStateToProps, mapDispathToTree)(DataModal)

