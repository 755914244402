import ProcessDesigner from "../../pages/components/ProcessDesigner/ProcessDesigner";
import React, {useEffect, useState } from "react";
import { message } from "antd";

// 引入样式
import "../../pages/pages/index.less";

export default function IndexPage() {
  const [visible, setVisible] = useState(
    document.documentElement.clientWidth > 1080,
  );

  useEffect(() => {
    watchClientWidth();
    window.addEventListener("resize", watchClientWidth);
  }, []);

  function watchClientWidth() {
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 1080) {
      if (visible) {
        setVisible(false);
        message.warning("请保证您的窗口宽带大于1080").then(() => {});
      }
    } else {
      setVisible(true);
    }
  }

  return (
      <div style={{ display: visible ? "unset" : "none" }}>
        <ProcessDesigner />
      </div>
  );
}
