import {
  ReduxAction,
  ReduxActionTypes,
} from "ce/constants/ReduxActionConstants";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { validateResponse } from "./ErrorSagas";

type TreeActionPayload = {
  datasourceId: any;
  deployName: string;
  body: string;
};

function* clearDataSage(action: ReduxAction<TreeActionPayload>) {
  yield put({
    type: ReduxActionTypes.CLEAR_DATA_ACTION,
    payload: [],
  });
}

export default function* clearData() {
  yield all([takeEvery(ReduxActionTypes.CLEAR_DATA, clearDataSage)]);
}
