import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";

export type TreeBack = {
  favorite: boolean;
  name: string;
  state: boolean;
};

const initialState: loadingReducerState = {
  loading: true,
};

const loadingRedux = createReducer(initialState, {
  [ReduxActionTypes.LOADING_STATE_ACTION]: (
    state: loadingReducerState,
    action: ReduxAction<TreeBack>,
  ) => {
    console.log(action);
    return {
      ...state,
      loading: action.payload.state,
    };
  },
});

export interface loadingReducerState {
  loading: boolean;
}

export default loadingRedux;
